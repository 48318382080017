import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getDetailPharmacy, submitPharmacyNote } from "../../../../actions";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import "./index.scss";
import moment from "moment";
import FormTiki from "./tiki";

const PharmacyDeliveryPrint = () => {
  const { search } = useLocation();
  const id = queryString.parse(search).id;
  const [note, setNote] = useState("");
  const [addNoteModal, setAddNoteModal] = useState(false);
  const pharmacyDelivery = useSelector((state) => state.pharmacyDelivery);
  const dispatch = useDispatch();

  useEffect(() => {
    let values = {
      partnerId: localStorage.getItem("parentId"),
    };

    let query = queryString.stringify(values);
    dispatch(getDetailPharmacy(id, query));
  }, []);

  useEffect(() => {
    if (pharmacyDelivery.details) {
      let detail =
        pharmacyDelivery.details.length != 0
          ? pharmacyDelivery.details[0]
          : pharmacyDelivery.details;

      setNote(detail.pharmacy_note);
    }
  }, [pharmacyDelivery.details]);

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center pt-4">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  const renderData = () => {
    let detail =
      pharmacyDelivery.details.length != 0
        ? pharmacyDelivery.details[0]
        : pharmacyDelivery.details;

    return (
      <>
        <div className="row" style={{ alignItems: "center" }}>
          {detail.pharmacyLogo ? (
            <div
              className="col-2"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={detail.pharmacyLogo}
                style={{ maxWidth: "100%", maxHeight: "80px" }}
              />
            </div>
          ) : null}
          <div className="col-10">
            <span style={{ fontSize: "32px", fontWeight: 600 }}>
              Informasi Pengiriman
            </span>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <hr />
          </div>
        </div>
        {detail.vendor_name == "TIKI" ? 
        <FormTiki detail={detail} />
        : 
        <>
        <div className="row" style={{ fontSize: "19px"}}>
          <div className="col">
            <div className="row">
              <div
                className="col-4"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div>Tanggal Resep</div>
                <div>:</div>
              </div>
              <div className="col-8">
                {detail.order_type == 2 || detail.order_type == 13
                  ? detail.prescriptionDate
                    ? detail.prescriptionDate
                    : ""
                  : detail.order_type == 19 || detail.order_type == 31
                  ? detail.orderDate
                    ? detail.orderDate
                    : ""
                  : ""}
              </div>
            </div>

            <div className="row">
              <div
                className="col-4"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div>Nama Dokter</div>
                <div>:</div>
              </div>
              <div className="col-8">
                {detail.order_type == 2
                  ? detail.doctor_name
                    ? detail.doctor_name
                    : ""
                  : detail.order_type == 19 || detail.order_type == 31
                  ? detail.remarks
                    ? detail.remarks
                    : ""
                  : detail.order_type == 13
                  ? ""
                  : ""}
              </div>
            </div>

            <div className="row">
              <div
                className="col-4"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div>Nama Pasien</div>
                <div>:</div>
              </div>
              <div className="col-8">
                {detail.destinationName ? detail.destinationName : "-"}
              </div>
            </div>

            <div className="row">
              <div
                className="col-4"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div>No. HP Pasien</div>
                <div>:</div>
              </div>
              <div className="col-8">
                {detail.destinationContactPhone
                  ? `+62${detail.destinationContactPhone}`
                  : "-"}
              </div>
            </div>

            <div className="row">
              <div
                className="col-4"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div>Alamat Pengiriman Detail</div>
                <div>:</div>
              </div>
              <div className="col-8">
                {`${detail.destinationNote ? detail.destinationNote : "-"} | ${detail.destinationAddress ? detail.destinationAddress : "-"}`}
              </div>
            </div>
          </div>

          <div className="col">
            <div className="row">
              <div
                className="col-4"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div>Order ID</div>
                <div>:</div>
              </div>
              <div className="col-8">{detail.order_id}</div>
            </div>

            <div className="row">
              <div
                className="col-4"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div>Metode Pengiriman</div>
                <div>:</div>
              </div>
              <div className="col-8">{detail.name ? detail.name : "-"}</div>
            </div>

            <div className="row">
              <div
                className="col-4"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div>Jadwal Pengiriman</div>
                <div>:</div>
              </div>
              <div className="col-8">
                {detail.schedule_time
                  ? `${moment(detail.schedule_date).format(
                      "DD MMM YYYY"
                    )} ${detail.schedule_time.substr(0, 5)}`
                  : "Segera"}
              </div>
            </div>

            <div className="row">
              <div
                className="col-4"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div>Nama Driver</div>
                <div>:</div>
              </div>
            </div>

            <div className="row">
              <div
                className="col-4"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div>No. Hp Driver</div>
                <div>:</div>
              </div>
            </div>

            <div className="row">
              <div
                className="col-4"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div>Catatan Farmasi</div>
                <div>:</div>
              </div>
              <div className="col-8">
                {detail.pharmacy_note ? detail.pharmacy_note : ""}
              </div>
            </div>
          </div>
        </div>
        </>
        }

      {detail.vendor_name !== "TIKI" ? 
      <>
        <div className="row mt-2" style={{ fontSize: "19px"}}>
          <div
            className="col-6"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "300px",
              }}
            >
              <span>Tanda Tangan,</span>

              <span>Farmasi</span>

              <span style={{ marginTop: "20px" }}>Nama :</span>
            </div>
          </div>
          <div
            className="col-6"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "300px",
              }}
            >
              <span>Tanda Tangan,</span>

              <span>Driver</span>

              <span style={{ marginTop: "20px" }}>Nama :</span>
            </div>
          </div>
        </div>
        </> : 
        <></>
      }
        <div
          className="col-11 mt-3">
          <span
            style={{
              fontWeight: 500
            }}>Apabila Anda mengalami kendala terkait Obat yang dikirimkan, Anda dapat menghubungi AIDO Care di nomor 0811-8481-436</span>
        </div>
        <div className="row mt-2" style={{ fontSize: "19px"}}>
          <div className="col">
            <div
              dangerouslySetInnerHTML={{
                __html: detail.pharmacy_form_notes
                  ? detail.pharmacy_form_notes
                  : "",
              }}
            />
          </div>
        </div>

        <div className="row mt-2">
          <div className="col">
            <hr />
          </div>
        </div>
      </>
    );
  };

  const handleAddNote = () => {
    const form = new FormData();

    form.append("orderId", id);
    form.append("note", note);

    dispatch(submitPharmacyNote(form))
      .then(() => {
        setAddNoteModal(false);

        let values = {
          partnerId: localStorage.getItem("parentId"),
        };

        let query = queryString.stringify(values);
        dispatch(getDetailPharmacy(id, query));
      })
      .catch((err) => {
        if (err.response) {
          Swal.fire({
            icon: "error",
            text: err.response.data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            text: err.message,
          });
        }
      });
  };

  const renderAddNote = () => {
    return (
      <Modal
        size="lg"
        show={addNoteModal}
        onHide={() => setAddNoteModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Catatan Farmasi
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col md>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Masukkan catatan farmasi"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col
              md
              className="form-group"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <button
                type="button"
                className="btn btn-primary"
                style={{ width: "50%" }}
                onClick={() => handleAddNote()}
              >
                Simpan
              </button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  };

  return pharmacyDelivery.loading ? (
    <Loading />
  ) : (
    <>
      <div style={{ width: "100%", padding: "10px" }}>{renderData()}</div>

      {pharmacyDelivery.details.length !== 0 &&
        pharmacyDelivery.details[0].isDoublePrint === 1 && (
          <div
            className="print-show"
            style={{ width: "100%", padding: "10px", display: "none" }}
          >
            {renderData()}
          </div>
        )}

      <div className="print-hide" style={{ width: "100%", padding: "10px" }}>
        <div className="row">
          <div className="col d-flex justify-content-end">
            <button
              className="btn btn-primary"
              style={{ width: "250px", marginRight: "10px" }}
              onClick={() => setAddNoteModal(true)}
            >
              Tambah Catatan Farmasi
            </button>

            <button
              className="btn btn-primary"
              style={{ width: "100px" }}
              onClick={() => window.print()}
            >
              Print
            </button>
          </div>
        </div>
      </div>
      {renderAddNote()}
    </>
  );
};

export default PharmacyDeliveryPrint;
