import React, { useEffect, useState } from "react";
import { Alert, Col, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  anterAjaPickup,
  getDetailPharmacyWaiting,
  gosendPickup,
  lalamovePickup,
  jntPickup,
  tikiPickup,
  grabExpressPickup
} from "../../../../actions";
import DashboardLayout from "../../Layout";
import { Link, useLocation } from "react-router-dom";
import queryString from "query-string";
import Swal from "sweetalert2";
import moment from "moment";
import { formatCurrency } from "../../../../helpers/currency";

const PharmacyWaitingDeliveryDetail = () => {
  const pharmacyWaitingDelivery = useSelector(
    (state) => state.pharmacyWaitingDelivery
  );
  const pharmacyDelivery = useSelector((state) => state.pharmacyDelivery);
  const { state } = useLocation();
  const dispatch = useDispatch();

  const role = JSON.parse(localStorage.getItem("user"));
  const roleUser = role.user_role_management_id.id;
  const [awbModal, setAwbModal] = useState(false);

  useEffect(() => {
    let values = {
      partnerId: localStorage.getItem("parentId"),
    };

    let query = queryString.stringify(values);
    dispatch(getDetailPharmacyWaiting(state, query));
  }, []);

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  const renderPrint = () => {
    var detail =
      pharmacyDelivery.details.length != 0
        ? pharmacyDelivery.details[0]
        : pharmacyDelivery.details;
    return roleUser != 44 ?
      (
        pharmacyDelivery.loading ? (
        <button
          type="button"
          className="btn btn-secondary"
          style={{ marginLeft: "10px", width: "150px" }}
          disabled
        >
          Loading...
        </button>
      ) : (
        <>
        {detail.vendor_name == "TIKI"  && detail.booking_id == null ? 
        (
          <>
            <button type="button" onClick={() => setAwbModal(true)} className="btn btn-secondary" style={{ marginLeft: "10px", width: "150px" }}>
              Print Preview
            </button>
          </> 
        ):(
          <Link
          to={{
            pathname: "/pharmacy-waiting-delivery/print",
            search: `?id=${state}`,
          }}
          target="_blank"
        >
          <button
            type="button"
            className="btn btn-primary"
            style={{ marginLeft: "10px", width: "150px" }}
          >
            Print Preview
          </button>
        </Link>
        )
        }
        </>
      )
    ) : null;
  };

  const handleRePickup = () => {
    var detail =
      pharmacyWaitingDelivery.details.length != 0
        ? pharmacyWaitingDelivery.details[0]
        : pharmacyWaitingDelivery.details;

    if (detail.length == 0) {
      return Swal.fire({
        icon: "warning",
        title: "Pharmacy Delivery Data Not Found",
      });
    }

    var vendorName = detail.vendor_name ? detail.vendor_name : "";
    var productPrice = detail.product_price
      ? parseInt(detail.product_price)
      : 0;
    var payerNet = detail.payer_net ? parseInt(detail.payer_net) : 0;
    var totalProductPrice =
      parseInt(productPrice) + parseInt(payerNet) >= 1000
        ? parseInt(productPrice) + parseInt(payerNet)
        : 1000;
    const form = new FormData();

    if (vendorName.toLowerCase() == "gosend") {
      form.append("paymentType", 3);
      form.append("collection_location", "pickup");
      form.append("shipment_method", "Instant");
      form.append("service_type", "ignore");
      form.append("originName", detail.originContactName);
      form.append("originNote", detail.originNote);
      form.append("originContactName", detail.originContactName);
      form.append("originContactPhone", detail.originContactPhone);
      form.append("originLatLong", detail.originLatlong);
      form.append("originAddress", detail.originAddress);
      form.append("destinationName", detail.destinationName);
      form.append("destinationNote", detail.destinationNote);
      form.append("destinationContactName", detail.destinationContactName);
      form.append("destinationContactPhone", detail.destinationContactPhone);
      form.append("destinationLatLong", detail.destinationLatlong);
      form.append("destinationAddress", detail.destinationAddress);
      form.append("item", "Obat");
      form.append("storeOrderId", detail.order_id);
      form.append("applied", "false");
      form.append("fee", "0");
      form.append("product_description", "Obat");
      form.append("product_price", totalProductPrice);

      dispatch(gosendPickup(form))
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Data Created",
            showConfirmButton: false,
          });
          setTimeout(
            () => (window.location = "/pharmacy-waiting-delivery"),
            1000
          );
        })
        .catch((err) => {
          if (err.response) {
            Swal.fire({
              icon: "info",
              text: err.response.data.message,
            }).then(function () {
              window.location.reload();
            });
          } else {
            Swal.fire({
              icon: "info",
              text: err.message,
            }).then(function () {
              window.location.reload();
            });
          }
        });
    } else if (vendorName.toLowerCase() == "anteraja") {
      var items = [];

      var itemsData = detail.item ? JSON.parse(detail.item) : [];

      for (var i = 0; i < itemsData.length; i++) {
        var patientNet = itemsData[i].patientNet
          ? parseInt(itemsData[i].patientNet)
          : 0;
        var payerNet = itemsData[i].payerNet
          ? parseInt(itemsData[i].payerNet)
          : 0;
        var declareVal =
          parseInt(patientNet) + parseInt(payerNet) >= 1000
            ? parseInt(patientNet) + parseInt(payerNet)
            : 1000;
        items.push({
          item_name: itemsData[i].name,
          item_desc: "Obat",
          item_category: "Obat",
          item_quantity: itemsData[i].qty ? parseInt(itemsData[i].qty) : 1,
          declared_value: declareVal,
          weight: 1000,
        });
      }

      form.append("booking_id", "AIDO-" + detail.order_id);
      form.append("invoice_no", "INV-" + detail.order_id);
      form.append("service_code", detail.vendor_type);
      form.append("parcel_total_weight", 1000);

      form.append("shipper_name", detail.originContactName);
      form.append("shipper_phone", detail.originContactPhone);
      form.append("shipper_email", detail.originEmail);
      form.append("shipper_district", detail.originDistrict);
      form.append("shipper_address", detail.originAddress);
      form.append("shipper_postcode", detail.originPostCode);
      form.append("shipper_geoloc", detail.originLatlong);

      form.append("receiver_name", detail.destinationName);
      form.append("receiver_phone", detail.destinationContactPhone);
      form.append("receiver_email", detail.destinationEmail);
      form.append("receiver_district", detail.destinationDistrict);
      form.append(
        "receiver_address",
        `${detail.destinationAddress}${
          detail.destinationNote ? ` (${detail.destinationNote})` : ""
        }`
      );
      form.append("receiver_postcode", detail.destinationPostCode);
      form.append("receiver_geoloc", detail.destinationLatlong);

      form.append("items", JSON.stringify(items));
      form.append("declared_value", totalProductPrice);
      form.append("use_insurance", true);
      form.append("expect_time", "");

      // For auto redirect to gosend
      form.append("origin_note", detail.originNote);
      form.append("destination_note", detail.destinationNote);
      form.append("destination_contact_name", detail.destinationContactName);
      form.append("destination_address", detail.destinationAddress);
      form.append("order_id", detail.order_id);

      dispatch(anterAjaPickup(form))
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Data Created",
            showConfirmButton: false,
          });
          setTimeout(
            () => (window.location = "/pharmacy-waiting-delivery"),
            1000
          );
        })
        .catch((err) => {
          if (err.response) {
            Swal.fire({
              icon: "info",
              text: err.response.data.message,
            }).then(function () {
              window.location.reload();
            });
          } else {
            Swal.fire({
              icon: "info",
              text: err.message,
            }).then(function () {
              window.location.reload();
            });
          }
        });
    } else if (vendorName.toLowerCase() == "lalamove") {
      form.append("senderName", `Farmasi ${detail.originContactName}`);
      form.append("senderPhone", `+62${detail.originContactPhone}`);
      form.append("senderNote", detail.originNote);
      form.append("destinationName", detail.destinationContactName);
      form.append("destinationPhone", `+62${detail.destinationContactPhone}`);
      form.append("destinationNote", detail.destinationNote);
      form.append("senderLatLong", detail.originLatlong);
      form.append("senderAddress", detail.originAddress);
      form.append("destinationLatLong", detail.destinationLatlong);
      form.append("destinationAddress", detail.destinationAddress);
      form.append("orderId", detail.order_id);
      form.append("partnerId", detail.partnerId);
      form.append("deliveryMethodId", detail.deliveryMethodID);

      dispatch(lalamovePickup(form))
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Data Created",
            showConfirmButton: false,
          });
          setTimeout(
            () => (window.location = "/pharmacy-waiting-delivery"),
            1000
          );
        })
        .catch((err) => {
          if (err.response) {
            Swal.fire({
              icon: "info",
              text: err.response.data.message,
            }).then(function () {
              window.location.reload();
            });
          } else {
            Swal.fire({
              icon: "info",
              text: err.message,
            }).then(function () {
              window.location.reload();
            });
          }
        });
    } else if (vendorName.toLowerCase() == "tiki") {
      var items = [];

      var itemsData = detail.item ? JSON.parse(detail.item) : [];

      for (var i = 0; i < itemsData.length; i++) {
        var patientNet = itemsData[i].patientNet
          ? parseInt(itemsData[i].patientNet)
          : 0;
        var payerNet = itemsData[i].payerNet
          ? parseInt(itemsData[i].payerNet)
          : 0;
        var declareVal =
          parseInt(patientNet) + parseInt(payerNet) >= 1000
            ? parseInt(patientNet) + parseInt(payerNet)
            : 1000;
        items.push({
          item_name: itemsData[i].name,
          item_desc: "Obat",
          item_category: "Obat",
          item_quantity: itemsData[i].qty ? parseInt(itemsData[i].qty) : 1,
          declared_value: declareVal,
          weight: 1000,
        });
      }

      form.append("booking_id", "AIDO-" + detail.order_id);
      form.append("invoice_no", "INV-" + detail.order_id);
      form.append("service_code", detail.vendor_type);
      form.append("parcel_total_weight", 1000);

      form.append("shipper_name", detail.originContactName);
      form.append("shipper_phone", detail.originContactPhone);
      form.append("shipper_email", detail.originEmail);
      form.append("shipper_district", detail.originDistrict);
      form.append("shipper_address", detail.originAddress);
      form.append("shipper_postcode", detail.originPostCode);
      form.append("shipper_geoloc", detail.originLatlong);

      form.append("receiver_name", detail.destinationName);
      form.append("receiver_phone", detail.destinationContactPhone);
      form.append("receiver_email", detail.destinationEmail);
      form.append("receiver_district", detail.destinationDistrict);
      form.append("warehouse_code", detail.originWarehouse);
      form.append("receiver_address", detail.destinationNote);
      form.append("receiver_postcode", detail.destinationPostCode);
      form.append("receiver_geoloc", detail.destinationLatlong);

      form.append("items", JSON.stringify(items));
      form.append("declared_value", totalProductPrice);
      form.append("use_insurance", true);
      form.append("expect_time", "");

      dispatch(tikiPickup(form))
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Data Created",
            showConfirmButton: false,
          });
          setTimeout(
            () => (window.location = "/pharmacy-waiting-delivery"),
            1000
          );
        })
        .catch((err) => {
          if (err.response) {
            Swal.fire({
              icon: "info",
              text: err.response.data.message,
            }).then(function () {
              window.location.reload();
            });
          } else {
            Swal.fire({
              icon: "info",
              text: err.message,
            }).then(function () {
              window.location.reload();
            });
          }
        });
    } else if (vendorName.toLowerCase() == "grab") {
      const [originLatitude, originLongitude] = detail.originLatlong.split(',');
      const [destinationLatitude, destinationLongitude] = detail.destinationLatlong.split(',');

      form.append("orderId", detail.order_id);
      form.append("serviceType", "INSTANT");
      form.append("itemName", "Medicine");
      form.append("itemDescription", "Medicine / Obat");
      form.append("originAddress", `${detail.originNote} | ${detail.originAddress}`);
      form.append("originLatitude", originLatitude);
      form.append("originLongitude", originLongitude);
      form.append("destinationAddress", `${detail.destinationNote} | ${detail.destinationAddress}`);
      form.append("destinationLatitude", destinationLatitude);
      form.append("destinationLongitude", destinationLongitude);
      form.append("recipientName", detail.destinationContactName);
      form.append("recipientPhone", `62${detail.destinationContactPhone}`);
      form.append("senderName", detail.originContactName);
      form.append("senderPhone", `62${detail.originContactPhone}`);
      form.append("productPrice", totalProductPrice);

      dispatch(grabExpressPickup(form))
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Data Created",
            showConfirmButton: false,
          });
          setTimeout(() => (window.location = "/pharmacy-delivery"), 1000);
        })
        .catch((err) => {
          if (err.response) {
            Swal.fire({
              icon: "info",
              text: err.response.data.message,
            }).then(function () {
              window.location.reload();
            });
          } else {
            Swal.fire({
              icon: "info",
              text: err.message,
            }).then(function () {
              window.location.reload();
            });
          }
        });
    }else if (vendorName.toLowerCase() == "Jnt") {
      form.append("senderName", `Farmasi ${detail.originContactName}`);
      form.append("senderPhone", `+62${detail.originContactPhone}`);
      form.append("senderNote", detail.originNote);
      form.append("destinationName", detail.destinationContactName);
      form.append("destinationPhone", `+62${detail.destinationContactPhone}`);
      form.append("destinationNote", detail.destinationNote);
      form.append("senderLatLong", detail.originLatlong);
      form.append("senderAddress", detail.originAddress);
      form.append("destinationLatLong", detail.destinationLatlong);
      form.append("destinationAddress", detail.destinationAddress);
      form.append("orderId", detail.order_id);
      form.append("partnerId", detail.partnerId);
      form.append("deliveryMethodId", detail.deliveryMethodID);

      dispatch(jntPickup(form))
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Data Created",
            showConfirmButton: false,
          });
          setTimeout(() => (window.location = "/pharmacy-delivery"), 1000);
        })
        .catch((err) => {
          if (err.response) {
            Swal.fire({
              icon: "info",
              text: err.response.data.message,
            }).then(function () {
              window.location.reload();
            });
          } else {
            Swal.fire({
              icon: "info",
              text: err.message,
            }).then(function () {
              window.location.reload();
            });
          }
        });
    }
  };

  const renderRePickup = () => {
    let detail =
      pharmacyWaitingDelivery.details.length != 0
        ? pharmacyWaitingDelivery.details[0]
        : pharmacyWaitingDelivery.details;
    let createdTime = new Date(detail.request_pickup_at).getTime();
    let currentTime = new Date().getTime();
    let countTime = currentTime - createdTime >= 900000 ? true : false;
    let isAvailable = false;
    let isLalamoveStatus =
      detail.delivery_status === "REJECTED" ||
      detail.delivery_status === "EXPIRED" ||
      detail.delivery_status === "CANCELED"
        ? true
        : false;
    if (
      (detail.deliveryMethodID == "14" && !detail.pickup_at && countTime) ||
      (detail.deliveryMethodID == "32" && detail.delivery_status === "CANCELED" || detail.delivery_status === 'FAILED' || detail.delivery_status === 'RETURNED') || //GrabExpress
      (detail.deliveryMethodID == "27" && !detail.pickup_at && isLalamoveStatus) 
    ) {
      isAvailable = true;
    }

    return roleUser != 44 ?
      (
        pharmacyDelivery.loading ? (
        <div className="d-flex justify-content-end">
          <button
            type="button"
            className="btn btn-secondary"
            style={{ marginRight: "10px", width: "100px" }}
            disabled
          >
            Loading...
          </button>
        </div>
      ) : isAvailable ? (
        <div className="d-flex justify-content-end">
          <button
            type="button"
            className="btn btn-primary"
            style={{ marginRight: "10px", width: "100px" }}
            onClick={() => handleRePickup()}
          >
            Re-Pickup
          </button>
        </div>
      ) : (
        <div className="d-flex justify-content-end">
          <button
            type="button"
            className="btn btn-secondary"
            style={{ marginRight: "10px", width: "100px" }}
            disabled
          >
            Re-Pickup
          </button>
        </div>
      )
    ) : null;
  };



  const renderDetails = () => {
    var detail =
      pharmacyWaitingDelivery.details.length != 0
        ? pharmacyWaitingDelivery.details[0]
        : pharmacyWaitingDelivery.details;

    let customFields = {}
    if(detail.customFields){
      customFields = JSON.parse(detail.customFields)
    }

    const separateByCategory = (fields) => {
      const orderData = [];
      const patientData = [];
    
      if (!fields) {
        return { orderData, patientData };
      }
      for (const [key, field] of Object.entries(fields)) {
        if (field && field?.category === 'orderData' && field?.isShown == '1') {
          orderData.push({ key, ...field });
        } else if (field && field?.category === 'patientData'&& field?.isShown == '1') {
          patientData.push({ key, ...field });
        }
      }
    
      return { orderData, patientData };
    };
    
    const { orderData, patientData } = separateByCategory(customFields);
    const labelCustomField = {
      medicalRecordNumber: 'Nomor Rekam Medis',
      orderDate:'Tanggal Resep',
      remarks:'Nama Dokter',
      queueNumber:'Nomor Antrian',
      drugType:'Jenis Obat',
      drugPrice:'Harga Obat',
      clinicName:'Nama Klinik',
      guarantorName:'Nama Penjamin',
      paymentReceiptNumber:'Nomor Bukti Pembayaran'
    }

    return pharmacyDelivery.loading ? (
      <tr>
        <td>
          <Loading />
        </td>
      </tr>
    ) : (
      <>
        <tr>
          <td style={{ width: "180px" }}>ID Order</td>
          <td>{detail.order_id ? detail.order_id : "-"}</td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Status Order</td>
          <td>{detail.order_status ? detail.order_status : "-"}</td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Nama Pasien</td>
          <td>{detail.destinationName ? detail.destinationName : "-"}</td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Tanggal Lahir Pasien</td>
          <td>
            {detail.birthday ? moment(detail.birthday).format("DD MMM YYYY") : "-"}
          </td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Nomor HP Pasien</td>
          <td>
            {detail.destinationContactPhone ? (
              <a
                style={{ color: "blue", textDecoration: "underline" }}
                href={detail.destinationContactPhoneWa}
                target="_blank"
              >
                {"+62" + detail.destinationContactPhone}{" "}
              </a>
            ) : (
              "-"
            )}
          </td>
        </tr>

        {
          patientData.map(item=>(
          <tr>
            <td style={{ width: "180px" }}>{labelCustomField[item.key]}</td>
            <td>
              {detail[item.key] ?detail[item.key] : "-"}
            </td>
          </tr>
          ))
        }

        <tr>
          <td style={{ width: "180px" }}>Alamat Pengiriman Detail</td>
          <td>{`${detail.destinationNote ? detail.destinationNote : "-"} | ${detail.destinationAddress ? detail.destinationAddress : "-"}`}</td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Jadwal Telekonsultasi</td>
          <td>
            {detail.scheduleDate
              ? moment(detail.scheduleDate).format("DD MMM YYYY")
              : "-"}
          </td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Metode Pengiriman</td>
          <td>{detail.name ? detail.name : "-"}</td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Jadwal Pengiriman</td>
          <td>
            {detail.schedule_time
              ? `${moment(detail.schedule_date).format(
                  "DD MMM YYYY"
                )} ${detail.schedule_time.substr(0, 5)}`
              : "-"}
          </td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Resi Pengiriman</td>
          <td>{detail.booking_id ? detail.booking_id : "-"}</td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Nama Driver</td>
          <td>{detail.driver_name ? detail.driver_name : "-"}</td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Nomor HP Driver</td>
          <td>
            {detail.driver_phone ? (
              <a
                style={{ color: "blue", textDecoration: "underline" }}
                href={detail.driver_phoneWa}
                target="_blank"
              >
                {"+62" + detail.driver_phone}{" "}
              </a>
            ) : (
              "-"
            )}
          </td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Status Pengiriman</td>
          <td>{detail.delivery_status ? detail.delivery_status : "-"}</td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Waktu Permintaan Pickup</td>
          <td>
            {detail.request_pickup_at
              ? moment(detail.request_pickup_at).format("DD MMM YYYY HH:mm")
              : "-"}
          </td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Waktu Pickup</td>
          <td>
            {detail.pickup_at
              ? moment(detail.pickup_at).format("DD MMM YYYY HH:mm")
              : "-"}
          </td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Nama Penerima</td>
          <td>{detail.receiver_name ? detail.receiver_name : "-"}</td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Lacak Pengiriman</td>
          <td>
            {detail.live_tracking_url ? (
              <a
                style={{ color: "blue", textDecoration: "underline" }}
                href={detail.live_tracking_url}
                target="_blank"
              >
                Track Delivery{" "}
              </a>
            ) : (
              "-"
            )}
          </td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Alasan Pembatalan</td>
          <td>
            {detail.cancellation_reason ? detail.cancellation_reason : "-"}
          </td>
        </tr>
        {
          orderData.map(item => {
            const key = item?.key
            return (
              key == 'orderDate' ?
                <tr>
                  <td style={{ width: "180px" }}>Tanggal Resep</td>
                  <td>
                    {detail?.order_type == 2 || detail?.order_type == 13
                      ? detail?.prescriptionDate
                        ? detail.prescriptionDate
                        : "-"
                      : detail?.order_type == 19 || detail?.order_type == 31
                        ? detail?.orderDate
                          ? detail.orderDate
                          : "-"
                        : "-"}
                  </td>
                </tr> :
                key == 'remarks'
                  ?
                  <tr>
                    <td style={{ width: "180px" }}>Nama Dokter</td>
                    <td>
                      {detail.order_type == 2
                        ? detail.doctor_name
                          ? detail.doctor_name
                          : "-"
                        : detail.order_type == 19 || detail.order_type == 31
                          ? detail.remarks
                            ? detail.remarks
                            : "-"
                          : detail.order_type == 13
                            ? "-"
                            : "-"}
                    </td>
                  </tr>
                  :
                  <tr>
                    <td style={{ width: "180px" }}>{labelCustomField[key]}</td>
                    <td>
                      {key == 'drugPrice'
                      ? !detail?.drugPrice ? `-`:`${detail?.drugPrice && formatCurrency(detail?.drugPrice)}`
                      : detail[key] ? detail[key] : "-"}
                    </td>
                  </tr>
              )
            })
          }
      </>
    );
  };

  const renderAwbEmpty = () => {
    return (
      <Modal
        size="sm"
        show={awbModal}
        onHide={() => setAwbModal(false)}
        centered
        >
        <Modal.Body>
          <Row className="mt-2">
            <Col
                md
                className="form-group"
                style={{ display: "flex", justifyContent: "center" }}
              >
              Silahkan lakukan request pickup sebelum mencetak label.
            </Col>
          </Row>
          <Row className="mt-2">
            <Col
              md
              className="form-group"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <button
                type="button"
                className="btn btn-primary btn-block"
                style={{ width: "25%", backgroundColor: "#c90e0e" }}
                onClick={() => setAwbModal(false)}
              >
                Cancel
              </button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <DashboardLayout>
      <div className="row revenue-title">
        <div className="col">
          <hr />
        </div>

        <div>Detail Dalam Proses Pengiriman</div>

        <div className="col">
          <hr />
        </div>
      </div>

      <div className="table-wrapper">
        <div className="wrapper-form-title">
          <span className="form-title">Dalam Proses Pengiriman</span>
        </div>

        <div className="row pb-4">
          <div className="col">{renderPrint()}</div>
          <div className="col">{renderRePickup()}</div>
        </div>

        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <tbody className="text-left">{renderDetails()}</tbody>
          </table>
        </div>

        <div className="form-title">
          <hr />
        </div>
      </div>
      {renderAwbEmpty()}
    </DashboardLayout>
  );
};

export default PharmacyWaitingDeliveryDetail;
