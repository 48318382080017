import axios from "../helpers/axios";
import axiosPartner from "../helpers/axiosPartner";
import axiosPartnerApi from "../helpers/axiosPartnerApi";
import { registerConstants } from "./constants";

export const getRegister = (search) => {
  return async (dispatch) => {
    dispatch({ type: registerConstants.GET_REGISTER_LIST_REQUEST });
    const res = await axios.get(`/Ipd/list?${search}`);
    if (res.status === 200) {
      dispatch({
        type: registerConstants.GET_REGISTER_LIST_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: registerConstants.GET_REGISTER_LIST_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getRegisterById = (id) => {
  return async (dispatch) => {
    dispatch({ type: registerConstants.GET_REGISTER_BY_ID_REQUEST });
    const res = await axios.get(`/Ipd/detail/${id}`);
    if (res.status === 200) {
      dispatch({
        type: registerConstants.GET_REGISTER_BY_ID_SUCCESS,
        payload: { userData: res.data },
      });
    } else {
      dispatch({
        type: registerConstants.GET_REGISTER_BY_ID_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getListPatients = (search) => {
  return async (dispatch) => {
    dispatch({ type: registerConstants.GET_REGISTER_LIST_REQUEST });
    const res = await axios.get(`/Ipd/search/patientRegisterOrder?${search}`);
    if (res.status === 200) {
      dispatch({
        type: registerConstants.GET_REGISTER_LIST_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: registerConstants.GET_REGISTER_LIST_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getAllPatientId = () => {
  return async (dispatch) => {
    dispatch({ type: registerConstants.GET_ALL_PATIENTS_REQUEST });
    const res = await axios.get(`/Ipd/allPatientId`, { mode: "cors" });
    if (res.status === 200) {
      dispatch({
        type: registerConstants.GET_ALL_PATIENTS_SUCCESS,
        payload: { allData: res.data },
      });
    } else {
      dispatch({
        type: registerConstants.GET_ALL_PATIENTS_SUCCESS,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getPatientById = (id) => {
  return async (dispatch) => {
    dispatch({ type: registerConstants.GET_REGISTER_BY_ID_REQUEST });
    const res = await axios.get(`/Ipd/patient/find/V2/${id}`);
    if (res.status === 200) {
      dispatch({
        type: registerConstants.GET_REGISTER_BY_ID_SUCCESS,
        payload: { userData: res.data[0] },
      });
    } else {
      dispatch({
        type: registerConstants.GET_REGISTER_BY_ID_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getRelation = () => {
  return async (dispatch) => {
    dispatch({ type: registerConstants.GET_RELATION_LIST_REQUEST });
    const res = await axios.get("/Ipd/relationList");
    if (res.status === 200) {
      dispatch({
        type: registerConstants.GET_RELATION_LIST_SUCCESS,
        payload: { relation: res.data },
      });
    } else {
      dispatch({
        type: registerConstants.GET_RELATION_LIST_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const findPatients = (form) => {
  return async (dispatch) => {
    dispatch({ type: registerConstants.FIND_PATIENT_DETAIL_REQUEST });
    const res = await axios.post("/Ipd/patient/find", form);
    if (res.status === 200) {
      dispatch({
        type: registerConstants.FIND_PATIENT_DETAIL_SUCCESS,
        payload: { patientDetail: res.data },
      });
    } else {
      dispatch({
        type: registerConstants.FIND_PATIENT_DETAIL_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const findAddress = (search) => {
  return async (dispatch) => {
    dispatch({ type: registerConstants.GET_ADDRESS_REQUEST });
    const res = await axios.get(`Ipd/address/find?${search}`);
    if (res.status === 200) {
      dispatch({
        type: registerConstants.GET_ADDRESS_SUCCESS,
        payload: { addressData: res.data },
      });
    } else {
      dispatch({
        type: registerConstants.GET_ADDRESS_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getPackage = (search) => {
  return async (dispatch) => {
    dispatch({ type: registerConstants.GET_PACKAGE_REQUEST });
    const res = await axios.get(`/Ipd/package/list?${search}`);
    if (res.status === 200) {
      dispatch({
        type: registerConstants.GET_PACKAGE_SUCCESS,
        payload: { packageData: res.data },
      });
    } else {
      dispatch({
        type: registerConstants.GET_PACKAGE_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getDoctor = () => {
  return async (dispatch) => {
    dispatch({ type: registerConstants.GET_DOCTOR_REQUEST });
    const res = await axios.get(`/Ipd/doctor/list`);
    if (res.status === 200) {
      dispatch({
        type: registerConstants.GET_DOCTOR_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: registerConstants.GET_DOCTOR_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const submitSingle = (form) => {
  return async (dispatch) => {
    dispatch({ type: registerConstants.SUBMIT_SINGLE_REQUEST });
    const res = await axios.post("/Ipd/submitData", form);
    if (res.status === 200) {
      dispatch({
        type: registerConstants.SUBMIT_SINGLE_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: registerConstants.SUBMIT_SINGLE_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const submitMultiple = (form) => {
  return async (dispatch) => {
    dispatch({ type: registerConstants.SUBMIT_MULTIPLE_REQUEST });
    const res = await axios.post("/Ipd/submitDataMultiple", form);
    if (res.status === 200) {
      dispatch({
        type: registerConstants.SUBMIT_MULTIPLE_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: registerConstants.SUBMIT_MULTIPLE_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const createOrderHomecare = (form) => {
  return async (dispatch) => {
    dispatch({ type: registerConstants.CREATE_ORDER_HOMECARE_REQUEST });
    const res = await axios.post("/Ipd/submitData/V2", form);
    if (res.status === 200) {
      dispatch({
        type: registerConstants.CREATE_ORDER_HOMECARE_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: registerConstants.CREATE_ORDER_HOMECARE_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const submitPatient = (form) => {
  return async (dispatch) => {
    dispatch({ type: registerConstants.SUBMIT_PATIENT_REQUEST });
    const res = await axios.post("/Ipd/submitDataPatient", form);
    if (res.status === 200) {
      dispatch({
        type: registerConstants.SUBMIT_PATIENT_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: registerConstants.SUBMIT_PATIENT_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getCountry = () => {
  return async (dispatch) => {
    dispatch({ type: registerConstants.GET_COUNTRY_REQUEST });
    const res = await axiosPartner.get(`/App/Customer/Me/Country`);
    if (res.status === 200) {
      dispatch({
        type: registerConstants.GET_COUNTRY_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: registerConstants.GET_COUNTRY_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getProvince = (id) => {
  return async (dispatch) => {
    dispatch({ type: registerConstants.GET_STATE_REQUEST });
    const res = await axiosPartner.get(`/App/Customer/Me/State/${id}`);
    if (res.status === 200) {
      dispatch({
        type: registerConstants.GET_STATE_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: registerConstants.GET_STATE_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getCity = (id) => {
  return async (dispatch) => {
    dispatch({ type: registerConstants.GET_CITY_REQUEST });
    const res = await axiosPartner.get(`/App/Customer/Me/City/${id}`);
    if (res.status === 200) {
      dispatch({
        type: registerConstants.GET_CITY_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: registerConstants.GET_CITY_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getDistrict = (id) => {
  return async (dispatch) => {
    dispatch({ type: registerConstants.GET_DISTRICT_REQUEST });
    const res = await axiosPartner.get(`/App/Customer/Me/District/${id}`);
    if (res.status === 200) {
      dispatch({
        type: registerConstants.GET_DISTRICT_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: registerConstants.GET_DISTRICT_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getSubDistrict = (id) => {
  return async (dispatch) => {
    dispatch({ type: registerConstants.GET_SUBDISTRICT_REQUEST });
    const res = await axiosPartner.get(`/App/Customer/Me/Subdistrict/${id}`);
    if (res.status === 200) {
      dispatch({
        type: registerConstants.GET_SUBDISTRICT_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: registerConstants.GET_SUBDISTRICT_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getPostalCode = (id) => {
  return async (dispatch) => {
    dispatch({ type: registerConstants.GET_POSTALCODE_REQUEST });
    const res = await axiosPartner.get(`/App/Customer/Me/PostalCode/${id}`);
    if (res.status === 200) {
      dispatch({
        type: registerConstants.GET_POSTALCODE_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: registerConstants.GET_POSTALCODE_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getScheduleData = (dataSch) => {
  return async (dispatch) => {
    dispatch({ type: registerConstants.GET_SCHEDULE_DATA_REQUEST });
    const res = await axios.post(`/Siloam/Schedule`, dataSch);
    if (res.status === 200) {
      var json = res.data;
      var schedule_data = JSON.parse(json.body);
      dispatch({
        type: registerConstants.GET_SCHEDULE_DATA_SUCCESS,
        payload: { schedule_data },
      });
    } else {
      dispatch({
        type: registerConstants.GET_SCHEDULE_DATA_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getScheduleNonSiloam = (partnerId) => {
  return async (dispatch) => {
    dispatch({ type: registerConstants.GET_SCHEDULE_NONSILOAM_REQUEST });
    const res = await axios.get(`Order/Schedule/${partnerId}`);
    if (res.status === 200) {
      var data = res.data;
      var schedule_data = Object.values(JSON.parse(JSON.stringify(data)));
      dispatch({
        type: registerConstants.GET_SCHEDULE_NONSILOAM_SUCCESS,
        payload: { schedule_data },
      });
    } else {
      dispatch({
        type: registerConstants.GET_SCHEDULE_NONSILOAM_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getRegisterPatient = (search) => {
  return async (dispatch) => {
    dispatch({ type: registerConstants.GET_REGISTERPATIENT_LIST_REQUEST });
    const res = await axios.get(`/Ipd/listPatient?${search}`);
    if (res.status === 200) {
      dispatch({
        type: registerConstants.GET_REGISTERPATIENT_LIST_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: registerConstants.GET_REGISTERPATIENT_LIST_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const findInsurances = (search) => {
  return async (dispatch) => {
    dispatch({ type: registerConstants.GET_INSURANCE_REQUEST });
    const res = await axios.get(`/Ipd/insurance/find?${search}`);
    if (res.status === 200) {
      dispatch({
        type: registerConstants.GET_INSURANCE_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: registerConstants.GET_INSURANCE_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const uploadInsuranceFile = (partnerId, file) => {
  const formData = new FormData();
  formData.append("file", file);

  return async (dispatch) => {
    dispatch({ type: registerConstants.UPLOAD_INSURANCE_REQUEST });
    const res = await axios.post(
      "/Ipd/insurance/UploadInsurance?id=" + partnerId,
      formData
    );
    if (res.status === 200) {
      dispatch({
        type: registerConstants.UPLOAD_INSURANCE_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: registerConstants.UPLOAD_INSURANCE_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const uploadIdentityFile = (partnerId, file) => {
  const formData = new FormData();
  formData.append("file", file);

  return async (dispatch) => {
    dispatch({ type: registerConstants.UPLOAD_IDENTITY_REQUEST });
    const res = await axios.post(
      "/Ipd/identity/UploadIdentity?id=" + partnerId,
      formData
    );
    if (res.status === 200) {
      dispatch({
        type: registerConstants.UPLOAD_IDENTITY_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: registerConstants.UPLOAD_IDENTITY_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const updatePatient = (form) => {
  return async (dispatch) => {
    dispatch({ type: registerConstants.UPDATE_PATIENT_REQUEST });
    const res = await axios.put("/Ipd/updateDataPatient", form);
    if (res.status === 200) {
      dispatch({
        type: registerConstants.UPDATE_PATIENT_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: registerConstants.UPDATE_PATIENT_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const findUserByPhoneEmail = (search) => {
  return async (dispatch) => {
    dispatch({ type: registerConstants.FIND_USERPHONEEMAIL_REQUEST });
    const res = await axios.get(`Ipd/user/findUserByPhoneEmail?${search}`);
    if (res.status === 200) {
      dispatch({
        type: registerConstants.FIND_USERPHONEEMAIL_SUCCESS,
        payload: { findUsers: res.data },
      });
    } else {
      dispatch({
        type: registerConstants.FIND_USERPHONEEMAIL_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const submitPharmacyByUnit = (pharmacyId, channelCode, form) => {
  return async (dispatch) => {
    dispatch({ type: registerConstants.SUBMIT_PHARMACY_UNIT_REQUEST });
    const res = await axios.post(
      "/Ipd/submitDataPharmacyUnit/" + pharmacyId + "/" + channelCode,
      form
    );
    if (res.status === 200) {
      dispatch({
        type: registerConstants.SUBMIT_PHARMACY_UNIT_SUCCESS,
        payload: res.data,
      });
      return res.data
    } else {
      dispatch({
        type: registerConstants.SUBMIT_PHARMACY_UNIT_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getDeliveryMethod = (form) => {
  return async (dispatch) => {
    dispatch({ type: registerConstants.GET_DELIVERY_METHOD_REQUEST });
    const res = await axiosPartnerApi.post(
      "/App/Snorlax/DeliveryMethod/getList",
      form
    );
    if (res.status === 200) {
      dispatch({
        type: registerConstants.GET_DELIVERY_METHOD_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: registerConstants.GET_DELIVERY_METHOD_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const findAddressByOrderId = (search) => {
  return async (dispatch) => {
    dispatch({ type: registerConstants.GET_ADDRESSBYORDER_REQUEST });
    const res = await axios.get(`Ipd/findAddressByOrderId?${search}`);
    if (res.status === 200) {
      dispatch({
        type: registerConstants.GET_ADDRESSBYORDER_SUCCESS,
        payload: { findUsers: res.data },
      });
    } else {
      dispatch({
        type: registerConstants.GET_ADDRESSBYORDER_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getOrderPriceGosend = (form) => {
  return async (dispatch) => {
    dispatch({ type: registerConstants.GET_GOSENDPRICE_REQUEST });
    const res = await axios.post("/Ipd/getOrderPriceGosend", form);
    if (res.status === 200) {
      dispatch({
        type: registerConstants.GET_GOSENDPRICE_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: registerConstants.GET_GOSENDPRICE_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const submitOrderPharmacyUnitGosend = (form) => {
  return async (dispatch) => {
    dispatch({ type: registerConstants.SUBMIT_PHARMACY_UNIT_REQUEST });
    const res = await axios.post("/Ipd/submitOrderPharmacyUnitGosend", form);
    if (res.status === 200) {
      dispatch({
        type: registerConstants.SUBMIT_PHARMACY_UNIT_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: registerConstants.SUBMIT_PHARMACY_UNIT_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const findOneTimeAddress = (query) => {
  return async (dispatch) => {
    dispatch({ type: registerConstants.GET_ONETIMEADDRESS_REQUEST });
    const res = await axios.get(`/Ipd/getPharmacyAddress?${query}`);
    if (res.status === 200) {
      dispatch({
        type: registerConstants.GET_ONETIMEADDRESS_SUCCESS,
        payload: res.data,
      });
      return res.data
    } else {
      dispatch({
        type: registerConstants.GET_ONETIMEADDRESS_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getPharmacyName = (query) => {
  return async (dispatch) => {
    dispatch({ type: registerConstants.GET_PHARMACY_NAME_REQUEST });
    const res = await axios.get(`/Ipd/getPharmacyName?${query}`);
    if (res.status === 200) {
      dispatch({
        type: registerConstants.GET_PHARMACY_NAME_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: registerConstants.GET_PHARMACY_NAME_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getDeliveryPrice = (form, channelCode) => {
  return async (dispatch) => {
    dispatch({ type: registerConstants.GET_DELIVERYPRICE_REQUEST });
    const res = await axios
      .post(`/Ipd/checkPharmacyDeliveryPrice/${channelCode}`, form)
      .catch((err) => {
        dispatch({
          type: registerConstants.GET_DELIVERYPRICE_FAILURE,
          payload: { error: err.response },
        });
      });

    if (res.status === 200) {
      dispatch({
        type: registerConstants.GET_DELIVERYPRICE_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: registerConstants.GET_DELIVERYPRICE_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const submitOneTimeAddress = (form) => {
  return async (dispatch) => {
    dispatch({ type: registerConstants.SUBMIT_ADDRESS_REQUEST });
    const res = await axios.post("/Ipd/createPharmacyAddress", form);
    if (res.status === 200) {
      dispatch({
        type: registerConstants.SUBMIT_ADDRESS_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: registerConstants.SUBMIT_ADDRESS_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const updatePharmacyAddress = (form) => {
  return async (dispatch) => {
    dispatch({ type: registerConstants.UPDATE_ADDRESS_REQUEST });
    const res = await axios.post("/Ipd/updatePharmacyAddress", form);
    if (res.status === 200) {
      dispatch({
        type: registerConstants.UPDATE_ADDRESS_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: registerConstants.UPDATE_ADDRESS_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getListHospital = () => {
  return async (dispatch) => {
    dispatch({ type: registerConstants.GET_HOSPITAL_LIST_REQUEST });
    const res = await axios.get(`/Ipd/list/siloHospital`);
    if (res.status === 200) {
      dispatch({
        type: registerConstants.GET_HOSPITAL_LIST_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: registerConstants.GET_HOSPITAL_LIST_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const findDetailKlinik = (pharmacyId, uuid) => {
  return async (dispatch) => {
    dispatch({ type: registerConstants.GET_DETAIL_CLINIC_REQUEST });
    const res = await axios.get(`/Ipd/getDetailKlinik/${pharmacyId}/${uuid}`);
    if (res.status === 200) {
      dispatch({
        type: registerConstants.GET_DETAIL_CLINIC_SUCCESS,
        payload: res.data,
      });
      return res.data
    } else {
      dispatch({
        type: registerConstants.GET_DETAIL_CLINIC_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const validatePromo = (query) => {
  return async (dispatch) => {
    dispatch({ type: registerConstants.GET_PROMO_VALIDATION_REQUEST });

      const res = await axios.get(`/Promo/Validate?${query}`);
      if (res.data.status === 200) {
        dispatch({
          type: registerConstants.GET_PROMO_VALIDATION_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: registerConstants.GET_PROMO_VALIDATION_FAILURE,
          payload: { error: res.data },
        });
        throw new Error(res.data.message)
      }
  };
};

