import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDetailPharmacyDeliveryDone } from "../../../../actions";
import DashboardLayout from "../../Layout";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import moment from "moment";
import { formatCurrency } from "../../../../helpers/currency";

const PharmacyDeliveryDoneDetail = () => {
  const pharmacyDeliveryDone = useSelector(
    (state) => state.pharmacyDeliveryDone
  );
  const { state } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    let values = {
      partnerId: localStorage.getItem("parentId"),
    };

    let query = queryString.stringify(values);
    dispatch(getDetailPharmacyDeliveryDone(state, query));
  }, []);

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  const renderDetails = () => {
    var detail =
      pharmacyDeliveryDone.details != null
        ? pharmacyDeliveryDone.details[0]
        : [];

    let customFields = {}
    if (detail.customFields) {
      customFields = JSON.parse(detail.customFields)
    }

    const separateByCategory = (fields) => {
      const orderData = [];
      const patientData = [];

      if (!fields) {
        return { orderData, patientData };
      }
      for (const [key, field] of Object.entries(fields)) {
        if (field && field?.category === 'orderData' && field?.isShown == '1') {
          orderData.push({ key, ...field });
        } else if (field && field?.category === 'patientData' && field?.isShown == '1') {
          patientData.push({ key, ...field });
        }
      }

      return { orderData, patientData };
    };

    const { orderData, patientData } = separateByCategory(customFields);
    const labelCustomField = {
      medicalRecordNumber: 'Nomor Rekam Medis',
      orderDate: 'Tanggal Resep',
      remarks: 'Nama Dokter',
      queueNumber: 'Nomor Antrian',
      drugType: 'Jenis Obat',
      drugPrice: 'Harga Obat',
      clinicName: 'Nama Klinik',
      guarantorName: 'Nama Penjamin',
      paymentReceiptNumber: 'Nomor Bukti Pembayaran'
    }


    return pharmacyDeliveryDone.loading ? (
      <tr>
        <td>
          <Loading />
        </td>
      </tr>
    ) : (
      <>
        <tr>
          <td style={{ width: "180px" }}>ID Order</td>
          <td>{detail.order_id ? detail.order_id : "-"}</td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Status Order</td>
          <td>{detail.order_status ? detail.order_status : "-"}</td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Nama Pasien</td>
          <td>{detail.destinationName ? detail.destinationName : "-"}</td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Tanggal Lahir Pasien</td>
          <td>
            {detail.birthday ? moment(detail.birthday).format("DD MMM YYYY") : "-"}
          </td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Nomor HP Pasien</td>
          <td>
            {detail.destinationContactPhone ? (
              <a
                style={{ color: "blue", textDecoration: "underline" }}
                href={detail.destinationContactPhoneWa}
                target="_blank"
              >
                {"+62" + detail.destinationContactPhone}{" "}
              </a>
            ) : (
              "-"
            )}
          </td>
        </tr>

        {
          patientData.map(item => (
            <tr>
              <td style={{ width: "180px" }}>{labelCustomField[item.key]}</td>
              <td>
                {detail[item.key] ? detail[item.key] : "-"}
              </td>
            </tr>
          ))
        }

        <tr>
          <td style={{ width: "180px" }}>Titik Pengiriman di Peta</td>
          <td>{detail.destinationAddress ? detail.destinationAddress : "-"}</td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Alamat Pengiriman Detail</td>
          <td>{detail.destinationNote ? detail.destinationNote : "-"}</td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Jadwal Telekonsultasi</td>
          <td>
            {detail.scheduleDate
              ? moment(detail.scheduleDate).format("LL")
              : "-"}
          </td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Metode Pengiriman</td>
          <td>{detail.name ? detail.name : "-"}</td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Jadwal Pengiriman</td>
          <td>
            {detail.schedule_time
              ? `${moment(detail.schedule_date).format(
                  "DD MMM YYYY"
                )} ${detail.schedule_time.substr(0, 5)}`
              : "-"}
          </td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Resi Pengiriman</td>
          <td>{detail.booking_id ? detail.booking_id : "-"}</td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Nama Driver</td>
          <td>{detail.driver_name ? detail.driver_name : "-"}</td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Nomor HP Driver</td>
          <td>
            {detail.driver_phone ? (
              <a
                style={{ color: "blue", textDecoration: "underline" }}
                href={detail.driver_phoneWa}
                target="_blank"
              >
                {"+62" + detail.driver_phone}{" "}
              </a>
            ) : (
              "-"
            )}
          </td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Status Pengiriman</td>
          <td>{detail.delivery_status ? detail.delivery_status : "-"}</td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Waktu Permintaan Pickup</td>
          <td>
            {detail.request_pickup_at
              ? moment(detail.request_pickup_at).format("DD MMM YYYY HH:mm")
              : "-"}
          </td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Waktu Pickup</td>
          <td>
            {detail.pickup_at
              ? moment(detail.pickup_at).format("DD MMM YYYY HH:mm")
              : "-"}
          </td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Waktu Penerimaan</td>
          <td>
            {detail.delivered_at
              ? moment(detail.delivered_at).format("DD MMM YYYY HH:mm")
              : "-"}
          </td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Nama Penerima</td>
          <td>{detail.receiver_name ? detail.receiver_name : "-"}</td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Lacak Pengiriman</td>
          <td>
            {detail.live_tracking_url ? (
              <a
                style={{ color: "blue", textDecoration: "underline" }}
                href={detail.live_tracking_url}
                target="_blank"
              >
                Track Delivery{" "}
              </a>
            ) : (
              "-"
            )}
          </td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Bukti Pengiriman (POD)</td>
          <td>
            {detail.pod ? (
              <a
                style={{ color: "blue", textDecoration: "underline" }}
                href={detail.pod}
                target="_blank"
              >
                Link POD
              </a>
            ) : (
              "-"
            )}
          </td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Alasan Pembatalan</td>
          <td>
            {detail.cancellation_reason ? detail.cancellation_reason : "-"}
          </td>
        </tr>

        {
          orderData.map(item => {
            const key = item?.key
            return (
              key == 'orderDate' ?
                <tr>
                  <td style={{ width: "180px" }}>Tanggal Resep</td>
                  <td>
                    {detail?.order_type == 2 || detail?.order_type == 13
                      ? detail?.prescriptionDate
                        ? detail.prescriptionDate
                        : "-"
                      : detail?.order_type == 19 || detail?.order_type == 31
                        ? detail?.orderDate
                          ? detail.orderDate
                          : "-"
                        : "-"}
                  </td>
                </tr> :
                key == 'remarks'
                  ?
                  <tr>
                    <td style={{ width: "180px" }}>Nama Dokter</td>
                    <td>
                      {detail.order_type == 2
                        ? detail.doctor_name
                          ? detail.doctor_name
                          : "-"
                        : detail.order_type == 19 || detail.order_type == 31
                          ? detail.remarks
                            ? detail.remarks
                            : "-"
                          : detail.order_type == 13
                            ? "-"
                            : "-"}
                    </td>
                  </tr>
                  :
                  <tr>
                    <td style={{ width: "180px" }}>{labelCustomField[key]}</td>
                    <td>
                      {key == 'drugPrice'
                      ? !detail?.drugPrice ? `-`:`${detail?.drugPrice && formatCurrency(detail?.drugPrice)}`
                      : detail[key] ? detail[key] : "-"}
                    </td>
                  </tr>
            )
          })
        }
      </>
    );
  };

  return (
    <DashboardLayout>
      <div className="row revenue-title">
        <div className="col">
          <hr />
        </div>

        <div>Detail Pengiriman Selesai</div>

        <div className="col">
          <hr />
        </div>
      </div>

      <div className="table-wrapper">
        <div className="wrapper-form-title">
          <span className="form-title">Pengiriman Selesai</span>
        </div>

        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <tbody className="text-left">{renderDetails()}</tbody>
          </table>
        </div>

        <div className="form-title">
          <hr />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default PharmacyDeliveryDoneDetail;
